import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `FinTech Design Agency`,
    text: "Dynamic FinTech UX Design",
    content: "We are a leading fintech design agency specializing in creating custom fintech solutions, delivering innovative user experiences catering to the needs of your fintech organization. Our expertise ensures seamless integration, simplified interfaces, and cutting-edge fintech UX designs that create standout experiences.",
    list: [
      'Customized Design Strategies',
      'Innovative UX Solutions',
      'Simplified User Interfaces'
    ]
  };
  const ourData = {
    title: `Reviews On Our <span class='h1-span'>FinTech Website Design</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Scale Business With Our <span class='h1-span'>Custom FinTech Solutions</span>",
    content: [
      {
        title: "12+ FinTech Designers",
        text: "We have a team of 12+ experienced fintech designers who ensure that our client's digital experiences are not only visually captivating but also highly functional.",
      },
      {
        title: "500+ Screens Simplified",
        text: "Our fintech design agency is committed to simplifying complex interfaces and has successfully delivered over 500 intuitive and visually appealing screen designs.",
      },
      {
        title: "90+ Completed Projects",
        text: "We have completed over 90+ fintech projects, giving us the extensive experience, knowledge, and skills necessary to tackle even the most intricate design challenges.",
      },
      {
        title: "Trusted by 100+ Clients",
        text: "Our fintech UX design agency has earned the trust and loyalty of over 100 clients, who rely on our expertise and innovative solutions to deliver exceptional results. ",
      },
    ],
  };
  const commonContent1 = {
    title: "Unveil Success With Our FinTech Design Agency",
    content: [
      {
        title: "Data-Driven Insights",
        text: "Our design process is guided by thorough user research and data analysis, allowing us to craft custom fintech solutions that are truly tailored to your user needs.",
      },
      {
        title: "Cross-Platform Optimization",
        text: "Our fintech website design is optimized across various devices and platforms, ensuring a consistent and high-quality user experience for your customers.",
      },
      {
        title: "Regulatory Compliance",
        text: "As experts in the fintech industry, we deeply understand the complex regulatory landscape and design your solutions to adhere to all relevant guidelines and standards.",
      },
      {
        title: "Scalable Solutions",
        text: "Our design approach is built to scale and adapt to accommodate new features, functionalities, and user requirements as your company expands and grows.",
      },
      {
        title: "Personalized Experiences",
        text: "We create tailored customer experiences that foster stronger emotional connections and increase user engagement, loyalty, and advocacy for your brand.",
      },
      {
        title: "Continuous Support",
        text: "We provide ongoing support, ensuring that your fintech solutions remain up-to-date, optimized, and aligned with evolving industry needs and user expectations.",
      },
    ],
  };
  const Questions = [
    "What services does your fintech design agency offer?",
    "What makes your agency different from others?",
    "How do you approach a new project?",
    "Can you help with both design and development?",
    "What is the typical timeline for a fintech project?"
  ];
  const faqData = [
    {
      para: [
        `We specialize in a range of services, including <a href='/ui-ux-designing/' title="UI UX design" target='_blank'>UI UX design</a>, mobile and web app development, <a href='/branding/' title="branding" target='_blank'>branding</a> and identity design, <a href='/user-research/' title="user research" target='_blank'>user research</a>, product strategy, and, more specifically, tailored for the fintech industry.`,
      ],
    },
    {
      para: [
        `Our deep understanding of the fintech industry, combined with our user-centric design approach, sets us apart. We focus on creating secure, compliant, innovative solutions that provide a seamless user experience and drive business growth.`
      ],
    },
    {
      para: [
        `Our approach is user-centric and collaborative. We start with an in-depth discovery phase to understand your goals, followed by user research, ideation, prototyping, user testing, and finally, design and development. We keep you involved at every step to ensure the final product aligns with your vision.`,
      ]
    },
    {
      para: [
        `Yes, we offer end-to-end services from initial design concepts to full-scale development. Our team includes expert designers, developers, and project managers to ensure seamless execution and delivery.`,
      ],
    },
    {
        para: [
            `Our timelines can vary depending on the complexity and scope of your project. A typical project can range from a few months for a simple app to a year or more for more complex platforms. We provide a detailed timeline during the proposal stage. <a title="Contact us" href="/contact-us/" tareget="_blank">Contact us</a> to get started.`,
        ]
    }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What services does your fintech design agency offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We specialize in a range of services, including UI UX design, mobile and web app development, branding and identity design, user research, product strategy, and, more specifically, tailored for the fintech industry."
              }
            },{
              "@type": "Question",
              "name": "What makes your agency different from others?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our deep understanding of the fintech industry, combined with our user-centric design approach, sets us apart. We focus on creating secure, compliant, innovative solutions that provide a seamless user experience and drive business growth."
              }
            },{
              "@type": "Question",
              "name": "How do you approach a new project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our approach is user-centric and collaborative. We start with an in-depth discovery phase to understand your goals, followed by user research, ideation, prototyping, user testing, and finally, design and development. We keep you involved at every step to ensure the final product aligns with your vision."
              }
            },{
              "@type": "Question",
              "name": "Can you help with both design and development?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, we offer end-to-end services from initial design concepts to full-scale development. Our team includes expert designers, developers, and project managers to ensure seamless execution and delivery."
              }
            },{
              "@type": "Question",
              "name": "What is the typical timeline for a fintech project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our timelines can vary depending on the complexity and scope of your project. A typical project can range from a few months for a simple app to a year or more for more complex platforms. We provide a detailed timeline during the proposal stage. Contact us to get started."
              }
            }]
          }                                            
        `}
      </script>
    </Helmet>
    <Layout hire="FinTech Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="FinTech Design Agency | FinTech UX Design"
    description="We are a leading fintech design agency specializing in innovative financial technology solutions. Elevate your product with our cutting-edge designs now!"
  />
);
